<template>
  <div class="goods-container" v-if="goods && goods.id">
    <div class="goods-box" @click="onToGoodsDetail">
      <div class="cover-image" v-if="coverUrl">
        <img :src="coverUrl"/>
      </div>
      <div class="desc">{{goods.description}}</div>
    </div>
  </div>
</template>

<script>
  export default {

    name: "GoodsCell",
    props: {
      goodsId: {},
    },
    data() {
      return {
        coverUrl: "",
        goods: {},
      }
    },
    mounted() {
      this.initGoodsDetail()
    },
    methods: {
      initGoodsDetail() {
        let params = {
          "id": this.goodsId,
        }

        this.$store.dispatch("GetGoodsById", params).then((data) => {
          if (data.code === 0) {
            this.goods = data.data
            if(this.skuItemId) {
              let skuItem = this.goods.sku_list.filter(c => c.id === this.skuItemId)[0]
              this.coverUrl = this.tryGetCoverUrl(this.goods, skuItem)
            } else {
              this.coverUrl = this.goods.cover_url
            }
          }
        }, () => {
        })
      },

      tryGetCoverUrl(goods, skuItem) {
        let coverUrl = ""
        try {
          if (skuItem && goods.sku_tree) {
            let s1 = skuItem.s1
            let sItem = goods.sku_tree[0].v.filter(c=> c.id === s1)[0]
            if (sItem) {
              coverUrl = sItem.imgUrl
            }
          }
        } catch (e) {
        }
        if (!coverUrl) {
          coverUrl = goods.cover_url
        }
        return coverUrl
      },

      onToGoodsDetail () {
        wx.miniProgram.navigateTo({
          url: "/pages/goods/index?id=" + this.goodsId
        })
      }
    }
  }

</script>

<style lang="scss" scoped>
  .goods-container {
    margin: 0 12px;
  }

  .goods-container .goods-box {
    padding: 4px 2px;
    background: #f7f8fa;
    display: flex;

    .cover-image img {
      width: 64px;
      height: 64px;
      -webkit-border-radius: 6px;
      -moz-border-radius: 6px;
      border-radius: 6px;
    }
  }
</style>
