<template>
  <div>
    <div class="moment-detail-page" v-if="item && item.id">
      <GoodsCell :goodsId="item.ref_item_id" v-if="item.ref_item_id"></GoodsCell>
      <div class="moment-container">
        <MomentDetail :item="item"></MomentDetail>
      </div>
      <CommentInfo :itemId="itemId"></CommentInfo>
    </div>
  </div>
</template>

<script>
  import {Toast, Pagination, Button, Icon} from "vant"
  import MomentDetail from "./MomentDetail";
  import CommentInfo from "../../comment/CommentInfo";
  import GoodsCell from "../../goods/GoodsCell";

  export default {
    name: "MomentDetailPage",
    components: {
      GoodsCell,
      Icon,
      CommentInfo,
      MomentDetail,
      Pagination,
      Button,
    },

    data() {
      return {
        itemId: 0,

        isLoading: true,
        item: {},
      }
    },

    async mounted() {
      this.$store.dispatch("parseRequestParams", this.$route.query)
      this.itemId = parseInt(this.$route.query.id)
      await this.initMomentData()
    },

    methods: {
      async initMomentData() {
        this.isLoading = true
        this.item = {}
        let queries = {}
        queries.id = this.itemId
        await this.$store.dispatch("GetUgcItem", queries).then((data) => {
              if (data.code !== 0) {
                Toast.fail("内容不存在")
              } else {
                this.item = data.data;
              }
            }, () => {
              Toast.fail("网络错误")
            }
        );
        this.isLoading = false
      },
    },
    computed: {}
  }
</script>

<style lang="scss" scoped>

  .moment-detail-page {
    padding-top: 18px;
    padding-bottom: calc(51px + constant(safe-area-inset-bottom));
    padding-bottom: calc(51px + env(safe-area-inset-bottom));
  }

  .moment-container {
    margin-top: 15px;
  }

</style>
