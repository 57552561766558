

export function createdAtDesc(created_at) {
  let d = new Date(created_at)
  let timestamp = d.getTime()
  let currentTimstamp = new Date().getTime()

  let timeDiff = (currentTimstamp - timestamp) / 1000
  let months = Math.floor(timeDiff / (86400 * 30))
  if (months > 1) {
    return "数月以前"
  }

  if (months >= 1 && months <= 6) {
    return `${months}个月以前`
  }

  let days = Math.floor(timeDiff / 86400)
  if (days >= 1) {
    return `${days}天前`
  }

  let hours = Math.floor(timeDiff / 3600)
  if (hours > 1) {
    return `${hours}小时前`
  }

  return "刚刚"
}
