<template>
  <div class="main-by">
    <div class="goods-container">
      <div class="goods-box">
        <div class="cover-image">
          <img :src="coverUrl"/>
        </div>
        <div class="desc-container">
          <div class="desc">{{goods.description}}</div>
          <div class="sku-desc">{{skuDesc}}</div>
        </div>
      </div>
    </div>
    <div class="rate-container">
      <div class="title">{{rateTitleDesc}}</div>
      <div class="rate-icon-container">
        <div class="rate-desc">{{rateDescMap[rateValue]}}</div>
        <Rate v-model="rateValue" color="#ffd21e" :disabled="rateDisabled"></Rate>
      </div>
    </div>
    <div class="moment-create-box">
      <MomentCreateComponent :refItemId="refItemId" :orderId="orderId" :config="config" @postStatus="onPost"
                             :extra="newMomentExtra"></MomentCreateComponent>
    </div>
    <div class="rate-anonymous" @click="onRateAnonymous">
      <div class="rate-box">
        <Checkbox v-model="isAnonymous" checked-color="#ff976a"></Checkbox>
        <div class="title">匿名评价</div>
      </div>
      <div class="desc">评价将以匿名形式展现</div>
    </div>
  </div>
</template>

<script>
  import MomentCreateComponent from "./MomentCreateComponent";
  import {Toast, Rate, Checkbox} from "vant"

  export default {
    name: "MomentCreateGoodsRemarkPage",
    components: {
      MomentCreateComponent,
      Toast,
      Rate,
      Checkbox,
    },
    data() {
      return {
        goods: {},
        skuDesc: "",
        coverUrl: "",
        config: {
          scene: "goods_remark",
          bucket: "goods_remark",
        },
        refItemId: 0,
        orderId: 0,
        skuItemId: 0,
        rateValue: 5,
        rateDisabled: false,
        rateDescMap: {
          5: "非常满意",
          4: "满意",
          3: "一般",
          2: "失望",
          1: "差评",
        },
        isAnonymous: false,
      }
    },
    async mounted() {
      this.$store.dispatch("parseRequestParams", this.$route.query)
      let refId = this.$route.query.id
      if (refId) {
        try {
          this.refItemId = parseInt(refId)
        } catch (e) {
        }
      }

      let skuId = this.$route.query.sku_id
      if (skuId) {
        try {
          this.skuItemId = parseInt(skuId)
        } catch (e) {
        }
      }

      let orderId = this.$route.query.order_id
      if (orderId) {
        try {
          this.orderId = parseInt(orderId)
        } catch (e) {
        }
      }
      this.initGoodsDetail()
    },
    methods: {
      onPost(status) {
        if (status === 0) {
          Toast.success('评价成功');
          setTimeout(() => {
            wx.miniProgram.navigateBack({
              delta: 1
            })
          }, 1000)
        } else {
          Toast.fail('发布失败')
        }
      },

      onRateAnonymous() {
        this.isAnonymous = !this.isAnonymous
      },

      tryGetCoverUrl(goods, skuItem) {
        let coverUrl = ""
        try {
          if (skuItem && goods.sku_tree) {
            let s1 = skuItem.s1
            let sItem = goods.sku_tree[0].v.filter(c => c.id === s1)[0]
            if (sItem) {
              coverUrl = sItem.imgUrl
            }
          }
        } catch (e) {
        }
        if (!coverUrl) {
          coverUrl = goods.cover_url
        }
        return coverUrl
      },

      initGoodsDetail() {
        let params = {
          "id": this.refItemId
        }
        this.$store.dispatch("GetGoodsById", params).then((data) => {
          if (data.code === 0) {
            this.goods = data.data
            if (this.skuItemId) {
              let skuItem = this.goods.sku_list.filter(c => c.id === this.skuItemId)[0]
              this.coverUrl = this.tryGetCoverUrl(this.goods, skuItem)
              this.skuDesc = (skuItem.property_desc + " " + skuItem.property_desc_2).trim()
            } else {
              this.coverUrl = this.goods.cover_url
            }

            if (this.goods.user_rate_value) {
              this.rateValue = this.goods.user_rate_value
              this.rateDisabled = true
            }
          }
        }, () => {
        })
      }
    },
    computed: {
      newMomentExtra() {
        let rateValue = this.rateValue
        if (this.rateDisabled) {
          rateValue = 0
        }

        return {
          rate: rateValue,
          isAnonymous: this.isAnonymous,
          skuDesc: this.skuDesc
        }
      },
      rateTitleDesc() {
        if (this.rateDisabled) {
          return "已评分"
        } else {
          return "评分"
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .main-by {
    padding: 24px 0;

  }

  .moment-create-box {
    padding: 18px 2px 0;
  }

  .goods-container {
    margin: 0 12px;
  }

  .goods-container .goods-box {
    padding: 4px 2px;
    background: #f7f8fa;
    display: flex;

    .cover-image img {
      width: 64px;
      height: 64px;
      -webkit-border-radius: 6px;
      -moz-border-radius: 6px;
      border-radius: 6px;
    }

    .desc-container {
      margin-left: 8px;
      .sku-desc {
        margin-top: 5px;
        font-size: 12px;
        color: grey;
      }
    }
  }

  .rate-container {
    padding: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title {
      font-weight: bold;
    }

    .rate-icon-container {
      display: flex;

      .rate-desc {
        margin-right: 8px;
        color: #333333;
      }
    }
  }

  .rate-anonymous {
    margin-top: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 8px;

    .rate-box {
      display: flex;
      align-items: center;

      .title {
        margin-left: 6px;
      }
    }

    .desc {
      color: #929292;
      font-size: 14px;
    }
  }
</style>
