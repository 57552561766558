<template>
  <div class="box-container">
    <div class="goods-body">
      <div class="cover-container" @click="onClickBuy(data.id)">
        <img :src="data.cover_url" class="goods-cover"/>
      </div>
      <div class="info-container" @click="onClickBuy(data.id)">
        <div class="title">
          {{data.title || data.description}}
        </div>
        <div class="bottom-container">
          <div class="price-container">
<!--            <div class="price">-->
<!--              ¥ {{data.price}}-->
<!--            </div>-->
<!--            <div v-if="data.activity_tags && data.activity_tags.length">-->
<!--              <tag plain type="danger" class="good-activity-tag">{{data.activity_tags[0]}}</tag>-->
<!--            </div>-->
          </div>
          <div class="btn-container">
            <Button type="warning" round size="mini" block>马上带走</Button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

  import {Button, Tag} from "vant"

  export default {
    name: "GoodsCard",
    props: {
      data: {
        type: Object,
      }
    },
    components: {
      Button, Tag
    },

    methods: {
      onClickBuy(goodId) {
        wx.miniProgram.navigateTo({
          url: "/pages/goods/index?id=" + goodId
        })
      }
    }

  }

</script>

<style lang="scss" scoped>
  .box-container {
    width: 100%;
  }
  .goods-body {
    padding: 10px 10px;
    margin: 0 10px 24px;
    background: rgba(255, 255, 255, 0.94);
    display: flex;
    align-items: center;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
  }

  .cover-container {
    position: relative;
    height: 80px;
    width: 80px;
    flex: 0 0 auto;

    img {
      width: 80px;
      height: 80px;
    }
  }

  .info-container {
    flex: 1 1 auto;
    margin-left: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 13px;

    .title {
      color: rgba(83, 83, 83, 0.91);
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
    }

    .bottom-container {
      margin-top: 8px;
      display: flex;

      .btn-container {
        margin-left: 12px;
        flex: 1 1 auto;
      }
    }

    .price-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex: 0 0 auto;

      .price {
        color: red;
      }

      .good-activity-tag {
        margin-left: 5px;
        background: transparent;
      }
    }
  }


</style>
