<template>
  <div class="avatar-container" @click="onClickUserAvatar">
    <div class="avatar" :style="{width: SizeValue, height: SizeValue}">
      <img :src="getAvatarUrl(userInfo)"/>
    </div>
  </div>
</template>

<script>
  export default {
    name: "UserAvatar",
    props: {
      size: {},
      userInfo: {},
    },
    data() {
      return {
        avatarUrlDefault: "http://a1.wanwanshequ.com/static/public/image57e4avatar.png",
      }
    },
    methods: {
      onClickUserAvatar() {
      },


      getAvatarUrl(user) {
        if (user && user.avatar_url) {
          return user.avatar_url
        } else {
          return this.avatarUrlDefault
        }
      },

    },

    computed: {
      SizeValue() {
        if (this.size) {
          return '' + this.size + 'px'
        } else {
          return '40px'
        }
      },
    }
  }
</script>

<style lang="scss" scoped>

  .avatar-container {

    .avatar {
      font-size: 10px;
      width: 40px;
      height: 40px;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 0 0 auto;
      overflow: hidden;

      img {
        width: 100%;
      }
    }
  }

</style>
