<template>
  <div class="global-article-body">
    <div class="global-loading-info" v-if="isLoading">
      <loading></loading>
    </div>
    <div class="globe-post-detail" v-if="item.id">
      <h1 class="post-title">
        {{item.title}}
      </h1>
      <div v-lazy-container="{ selector: 'img' }">
        <div class="post-content" v-html="item.content"></div>
      </div>
    </div>
    <transition name="fade">
      <goods-card :data="relatedGood" v-if="relatedGood.id && showGood" class="good-card"
                  @click.native="onClickGoodsCard(relatedGood.id)"></goods-card>
    </transition>
  </div>
</template>

<script>
  import {Toast, Icon} from "vant"
  import Loading from "@/components/common/Loading"
  import GoodsCard from "@/components/common/GoodsCard";

  export default {
    name: "ArticleDetailPage",
    components: {
      GoodsCard,
      Loading,
      Icon
    },
    data() {
      return {
        itemId: 0,
        isLoading: true,
        showGood: false,
        item: {},
        relatedGood: {},
        timerView: null,
      }
    },
    async mounted() {
      await this.initItem()
      this.loadItemRelatedGoods()
      setTimeout(() => {
        this.showGood = true
      }, 5000)

      this.timerView = setTimeout(() => {
        this.emitAction()
      }, 3000)
    },

    destroyed() {
      if (this.timerView) {
        clearTimeout(this.timerView)
      }
    },

    methods: {
      async initItem() {
        this.itemId = this.$route.params.id
        let queries = {}
        queries.id = this.itemId
        await this.$store.dispatch("GetUgcItem", queries).then((data) => {
          if (data.code !== 0) {
            Toast.fail("内容不存在")
          } else {
            this.item = data.data;
          }
        }, () => {
          Toast.fail("网络错误")
        })
        this.isLoading = false
      },

      onClickGoodsCard(goodsId) {
        console.log("here", goodsId)
        let params = {
          item_id: this.itemId,
          item_type: "ugc_article",
          article_record_id: goodsId,
          action: "view_recommend",
        }
        this.$store.dispatch("UserItemActionStats", params)
      },

      emitAction() {
        let params = {
          item_id: this.itemId,
          item_type: "ugc_article",
          action: "view",
        }
        this.$store.dispatch("UserItemActionStats", params)
      },

      loadItemRelatedGoods() {
        if (!this.item || !this.item.item_attrs) {
          return
        }

        let related_goods = this.item.item_attrs.related_goods
        if (!related_goods) {
          return;
        }

        let parsedObj = JSON.parse(related_goods)
        if (parsedObj && parsedObj.good_ids) {
          let goodId = parsedObj.good_ids.split(',')[0]
          this.$store.dispatch("GetGoodsById", {id: goodId}).then((data) => {
            if (data.code === 0) {
              this.relatedGood = data.data
            }
          })
        }
      }
    }
  }
</script>

<style lang="scss">
  .global-article-body {
    background: white;
  }

  .global-loading-info {
  }

  .globe-post-detail {
    position: relative;
    width: 100%;

    .post-title {
      padding: 24px 10px;
      color: #393939;
      font-size: 22px;
      line-height: 28px;
    }

    .post-content {
      padding: 0 10px 20px 10px;
      line-height: 25px;
      font-size: 15px;
      word-break: break-all;
      word-wrap: break-word;
      color: #2a2a2a;

      img {
        display: block;
        margin: 10px auto;
        max-width: 96% !important;
        height: auto !important;
      }
    }
  }

  .good-card {
    position: fixed;
    bottom: 15px;
    right: 0;
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }

  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
  {
    opacity: 0;
  }
</style>
