<template>
  <div>
    <div>
      <div class="create-container">
        <Field
            v-model="description"
            rows="1"
            :autosize="{minHeight: 100, maxHeight:150}"
            type="textarea"
            placeholder="分享一下你收到的宝贝吧..."
            maxlength="2000"
            class="input-container"
        >
        </Field>
        <div class="uploader-container">
          <Uploader
              v-model="fileList"
              :after-read="onAfterRead"
              multiple
              :max-count="9"
          />
        </div>

      </div>
    </div>
    <div class="submit-btn-container"><Button @click="onClickPost" round type="warning" class="btn">提交</Button></div>
  </div>
</template>

<script>
  import {Field, Uploader, Toast, Button, Icon, Rate, Checkbox} from "vant"

  export default {
    name: "MomentCreateComponent",
    components: {
      Icon, Field, Uploader, Toast, Button, Rate, Checkbox
    },
    props: {
      config: {},
      refItemId: {},
      orderId: {},
      extra: {},
    },
    data() {
      return {
        postLoading: false,
        title: "",
        description: "",

        userInfo: {},

        autoSize: {
          minHeight: 30
        },

        fileList: [],
        fileImageUrisMap: {},
      }
    },
    async mounted() {
      if (this.config.backup_key) {
        this.loadBackupData(this.config.backup_key)
      }
    },
    beforeDestroy() {
      if (this.config.backup_key) {
        this.saveBackUpData(this.config.backup_key)
      }
    },
    methods: {
      saveBackUpData(backupKey) {
        let file_list = []
        let data = {}

        for (let i = 0; i < this.fileList.length; i++) {
          file_list.push({
            "url": this.fileList[i].url,
            "uri": this.fileList[i].uri,
          })
        }

        data = {
          title: this.title,
          description: this.description,
          file_list: file_list,
        }

        try {
          localStorage.setItem(backupKey, JSON.stringify(data))
        } catch (e) {
          console.log(e)
        }
      },

      loadBackupData(backupKey) {
        try {
          let s = localStorage.getItem(backupKey)
          if (s) {
            let data = JSON.parse(s)
            if (data.title) {
              this.title = data.title
            }
            if (data.description) {
              this.description = data.description
            }
            if (data.file_list) {
              for (let i = 0; i < data.file_list.length; i++) {
                this.fileList.push({url: data.file_list[i].url, uri: data.file_list[i].uri})
              }
            }
          }
        } catch (e) {
          console.log(e)
        }
      },

      async onClickPost() {
        let imageUris = []
        for (let i = 0; i < this.fileList.length; i++) {
          if (this.fileList[i].status === "done") {
            imageUris.push(this.fileList[i].uri)
          }
        }

        if (!this.title && !this.description && (!imageUris || imageUris.length === 0) && !this.extra.rate) {
          Toast("请输入内容")
          return
        }
        this.postLoading = true

        let params = {
          title: this.title,
          description: this.description,
          image_uris: imageUris,
        }

        if (this.refItemId) {
          params.ref_item_id = parseInt(this.refItemId)
        }

        if (this.orderId) {
          params.order_id = parseInt(this.orderId)
        }

        if (this.config.scene) {
          params.scene = this.config.scene
        }

        if (this.extra.rate) {
          params.rate_value = this.extra.rate
        }

        if (this.extra.isAnonymous) {
          params.is_anonymous = this.extra.isAnonymous
        }

        if (this.extra.skuDesc) {
          params.sku_desc = this.extra.skuDesc
        }

        await this.$store.dispatch("GoodsUserRemark", params).then((data) => {
          if (data.code === 0) {
            Toast("发表成功")
            this.title = ""
            this.description = ""
            this.fileList = []
            this.$emit("postStatus", data.code)
          } else if (data.code === 20010) {
            this.$emit("postStatus", data.code)
          }
        }, (err) => {
          console.log(err)
          Toast("网络错误")
        })
        this.postLoading = false
      },

      onAfterRead(file) {
        let bucket = this.config.bucket
        if (!bucket) {
          bucket = 'moment'
        }
        let params = new FormData()
        params.append('file', file.file)
        params.append("bucket", bucket)
        params.append("source", "create_moment")
        file.status = "uploading"

        this.$store.dispatch("UploadImage", params).then((data) => {
          if (data.code === 0) {
            file.status = "done"
            file.uri = data.data.uri
            file.url = data.data.image_url
          } else {
            file.status = "failed"
          }
        }, () => {
          file.status = "failed"
        })
      },

    },
    watch: {},
    computed: {}
  }
</script>

<style lang="scss" scoped>
  .create-container {
    padding: 8px;
  }

  .input-container {
    background: #f7f8fa;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    overflow: hidden;
  }

  .uploader-container {
    margin-top: 10px;
  }


  .submit-btn-container {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 0 12px;
    padding-bottom: calc(12px + constant(safe-area-inset-bottom));
    padding-bottom: calc(12px + env(safe-area-inset-bottom));

    .btn {
      width: 80%;

    }
  }
</style>
