<template>
  <div class="brand-cell-container">
    <div class="title">
      <div class="left">{{item.title}}</div>
    </div>
    <div class="content">
      <div class="cell-item">
        <div class="left">营业时间：</div>
        <div class="right">{{item.service_time}}</div>
      </div>
      <div class="cell-item">
        <div class="left">商家电话：</div>
        <div class="right">{{displayTel}}
          <div class="icon-box" @click="onCall">
            <Tag size="large" round  type="warning">
              <Icon name="phone"/>
              <a :href="phoneCall" class="phone-call">拨号</a>
            </Tag>
          </div>
        </div>
      </div>
      <div class="cell-item">
        <div class="left">商家地址：</div>
        <div class="right">{{displayAddress}}
          <div @click="onToMapAddress" class="icon-box">
            <Tag size="large" round type="warning">
              <Icon name="map-marked"/>
              导航
            </Tag>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {Tag, Icon} from "vant"

  export default {
    name: "StoreItem",
    components: {
      Tag, Icon
    },
    props: {
      item: {
        type: Object,
        value: {},
      }
    },
    data() {
      return {
        telActions: [],
      }
    },
    methods: {
      onCall(){

      },

      onToMapAddress() {
        let poi = this.item.poi
        if (!poi || !poi.lat || !poi.lng) {
          return
        }

        wx.openLocation({
          latitude: poi.lat,
          longitude: poi.lng,
          scale: poi.scale || 14,
          name: this.item.title,
          address: this.displayAddress,
        })
      },
    },
    mounted() {
      let item = this.item
      if (item.tel_list) {
        for (let i = 0; i < item.tel_list.length; i++) {
          this.telActions.push({
            "name": item.tel_list[i]
          })
        }
      }
    },
    computed: {
      displayTel() {
        if (this.item.tel_list) {
          return this.item.tel_list.join(",")
        }
        return ""
      },
      displayAddress(){
        let add = this.item.address
        if(add) {
          return add.province + add.county + add.city + add.addressDetail
        } else {
          return ""
        }
      },
      phoneCall() {
        if (this.item && this.item.tel_list) {
          return "tel:" + this.item.tel_list[0]
        } else {
          return ""
        }
      }
    }
  }

</script>

<style lang="scss" scoped>
  .brand-cell-container {
    font-size: 14px;
    background: white;
    padding: 0 10px;

    .title {
      font-size: 16px;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #fafafa;
      padding: 12px 0;

      .left {
        color: rgba(17, 17, 17, 0.877);
      }
    }
  }

  .brand-cell-container .content {
    padding-bottom: 12px;
    color: rgba(37, 37, 37, 0.726);
  }

  .brand-cell-container .cell-item {
    font-size: 14px;
    display: flex;
    margin-top: 7px;
    justify-content: space-between;
  }

  .cell-item .left {
    flex: 0 0 auto;
  }

  .cell-item .right{
    margin-left: 12px;
    display: flex;
    align-items: center;
  }

  .cell-item .right .icon-box {
    margin-left: 12px;
    flex: 0 0 auto;
  }

  .brand-cell-container .title .right {
    font-size: 14px;
    display: flex;
    align-items: center;
  }

  .phone-call {
    color: white;
  }

</style>
