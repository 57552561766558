<template>
  <div class="refund-page">
    <div class="refund-tip" v-if="refundTip">
      <NoticeBar wrapable :scrollable="false"
                 left-icon="info-o">
        <div>{{refundTip}}</div>
      </NoticeBar>
    </div>
    <div class="order-item-container" v-if="item && item.id">
      <div class="title"><Icon name="bag-o" /> <div class="txt">选择需要退款的产品</div></div>
      <checkbox-group class="card-goods" v-model="checkedGoods">
        <checkbox  checked-color="#ff976a" class="card-goods__item" v-for="item in orderItemList" :key="item.sku_id" :name="item.sku_id">
          <card :title="getTitle(item)" :num="item.order_count"
                :thumb="item.order_product_image_url">
            <div slot="footer" class="footer">
              <span class="add" @click.stop="reduceCount(item,item.order_count)">
                  <Button class="btn" size="mini">-</Button>
              </span>
              <span class="input">
                  <span class="input-edit">{{item.order_count}}</span>
              </span>
            </div>
          </card>
        </checkbox>
      </checkbox-group>
    </div>
    <div class="user-input">
      <div class="title"><Icon name="warning-o" /> <div class="txt">请填写退款原因</div></div>
      <Field
          v-model="reason"
          rows="3"
          autosize
          label-width="44px"
          label="原因"
          type="textarea"
          placeholder="请输入退款原因"
          class="field-input"
      />
    </div>
    <div class="btn-container">
      <Button round type="warning" plain @click="onSubmit">确认提交退款申请</Button>
    </div>
  </div>
</template>

<script>
  import {Dialog, NoticeBar, Button, Icon, Checkbox, Toast, CheckboxGroup, Card, Field} from "vant"

  export default {
    name: "MomentDetailPage",
    title: "申请退款",
    components: {
      Icon, Checkbox, CheckboxGroup, Card, Button, Field, NoticeBar
    },

    data() {
      return {
        orderId: 0,

        isLoading: true,
        item: {},

        reason: "",

        orderItemList: [],
        checkedGoods: [],
      }
    },

    async mounted() {
      this.$store.dispatch("parseRequestParams", this.$route.query)
      this.orderId = parseInt(this.$route.query.id)
      await this.initOrderData()
    },

    methods: {
      async initOrderData() {
        this.isLoading = true
        this.item = {}
        let queries = {}
        queries.id = this.orderId
        await this.$store.dispatch("GetOrderById", queries).then((data) => {
          if (data.code === 0) {
            this.item = data.data
            let itemsList = []
            for (let i = 0; i < this.item.order_item_group.length; i++) {
              itemsList.push(...this.item.order_item_group[i].order_items)
            }
            this.orderItemList = itemsList
          }
        }, () => {
          Toast("网络错误")
        })
        this.isLoading = false
      },

      formatPrice(price) {
        return price.toFixed(2);
      },

      getTitle(item) {
        if (item.order_item_description) {
          return item.good_info.title + ' 「' + item.order_item_description + '」'
        } else {
          return item.good_info.title
        }
      },

      reduceCount(item, count) {
        if (!count && count !== 0) {
          Toast({
            position: "bottom",
            message: "数值不正确~"
          });
          return;
        }
        if (count <= 1) {
          Toast({
            position: "bottom",
            message: "不能再少了~"
          });
          return;
        }
        count--;
        item.order_count = count;
      },

      onSubmit() {
        if (this.checkedGoods.length === 0) {
          Toast("请选择商品")
          return
        }

        if (!this.reason) {
          Toast("请输入原因")
          return
        }

        Dialog.confirm({
          message: '确认提交退款申请',
        }).then(() => {
          let item = []
          for (let i = 0; i < this.checkedGoods.length; i++) {
            for (let j = 0; j < this.orderItemList.length; j++) {
              if (this.orderItemList[j].sku_id !== this.checkedGoods[i]) {
                continue
              }
              item.push({
                "sku_comb_id": this.orderItemList[j].sku_id,
                "good_id": this.orderItemList[j].good_info.id,
                "order_count": this.orderItemList[i].order_count
              })
              break
            }
          }
          let params = {
            "reason": this.reason,
            "order_item_list": item,
            "id": this.orderId,
          }
          this.$store.dispatch("RequestRefund", params).then((data) => {
            if (data.code === 0) {
              Toast("提交成功")
              setTimeout(() => {
                wx.miniProgram.navigateBack({
                  delta: 1
                })
              }, 1000)
            } else {
              Toast("系统错误")
            }
          }, () => {

          })
        }).catch(() => {
          // on cancel
        });
      }
    },
    computed: {
      refundTip() {
        if (!this.item || !this.item.id) {
          return ""
        }
        if (this.item.status === "shipped") {
          return "您好, 由于产品已发货，如需退款，请在物流配送时拒收，待产品返回仓库后会安排退款，如已收货，请先与客服联系，感谢支持！"
        } else {
          return "您好，部分产品回填发货订单由延迟，有可能在您申请退款的过程中，产品已发货，因此若物流已在配送中，请配合拒收快递，待产品返回仓库后会给您安排退款，感谢支持！"
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .refund-page {
    padding-bottom: calc(51px + constant(safe-area-inset-bottom));
    padding-bottom: calc(51px + env(safe-area-inset-bottom));
  }

  .refund-tip {
    padding: 12px 0;
  }

  /*margin: 25px;*/
  /*padding: 8px;*/
  /*border: 1px solid rgba(81, 81, 81, 0.8);*/
  /*color: #333;*/
  /*-webkit-border-radius: 8px;*/
  /*-moz-border-radius: 8px;*/
  /*border-radius: 8px;*/

  .title {
    padding: 12px 0;
    color: #333;
    font-weight: bold;
    display: flex;
    align-items: center;
    .txt {
      margin-left: 5px;
    }
  }

  .order-item-container {
    margin-top: 8px;
    padding: 0 15px;
  }


  .footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .add {
      .btn {
        min-width: 34px;
      }
    }

    .input {
      margin-left: 8px;
    }
  }

  .user-input {
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    overflow: hidden;
    margin-top: 8px;
    padding: 14px;

    .field-input {
      background: #fafafa;
    }
  }

  .btn-container {
    text-align: center;
    margin-top: 18px;
  }


</style>
