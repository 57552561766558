<template>
  <div class="moment-body">
    <div class="moment-item-container" v-if="item && item.id">
      <div class="title-container">
        <div class="user-info-container">
          <UserAvatar :userInfo="item.user" :size="35"></UserAvatar>
          <div class="base-info-container">
            <div class="nickname">{{getNickName(item)}}</div>
            <div class="post-time">{{createdAt(item)}}</div>
          </div>
        </div>
        <div class="more-container" @click="onClickMore" v-if="isAuthor()">
          <icon-svg icon-class="more"></icon-svg>
        </div>
      </div>
      <div class="moment-info-box">
        <div class="title-info">{{item.title}}</div>
        <div class="description-info" v-if="getMomentDescription(item)">{{getMomentDescription(item)}}</div>
        <div class="cover-container" v-if="imageUrls(item).length > 0">
          <ul class="cover-inner flex">
            <li v-for="(imgUrl, idx) in imageUrls(item)" :key="idx" @click="onClickPreview(idx)">
              <img v-lazy="imgUrl" class="img">
            </li>
          </ul>
        </div>
      </div>
      <div class="item-action-box">
        <div class="action-item-container" @click="onClickDigg">
          <icon-svg icon-class="like" v-if="!isDigg"></icon-svg>
          <icon-svg icon-class="like-fill" style="color: red;" v-else></icon-svg>
        </div>
        <div class="icon-text">{{diggCountText}}</div>
      </div>
      <image-preview v-model="showPreview" :startPosition="previewIndex" :urlList="imageUrls(item)"></image-preview>
    </div>
    <ActionSheet v-model="showActions"  :actions="actions"  :round="false" cancel-text="取消" close-on-click-action @select="onSelect" style="z-index:10000"></ActionSheet>
  </div>
</template>

<script>
  import {Icon, Toast, Dialog, ActionSheet} from "vant"

  import ImagePreview from "../../common/ImagePreview";
  import {createdAtDesc} from "../../../util/common";
  import UserAvatar from "../../common/UserAvatar";
  export default {
    name: "MomentDetail",
    components: {UserAvatar, ActionSheet, ImagePreview, Icon, Toast},
    props: {
      item: {},
    },
    data() {
      return {
        itemId: 0,
        showLoading: true,
        addDigg: 0,
        isDigg: false,
        showActions: false,
        actions: [{ name: '删除' }],

        previewIndex: 0,
        showPreview: false,

        itemStatusAuthorDelete: 3,
      }
    },
    mounted() {
      this.itemId = this.item.id
      if (this.item.is_digg) {
        this.isDigg = true
      }
    },
    methods: {
      onClickMore() {
        this.showActions = true
      },
      onSelect(item) {
        // 默认情况下点击选项时不会自动收起
        // 可以通过 close-on-click-action 属性开启自动收起
        this.showActions = false;
        if(item.name === "删除") {
          this.onClickDelete()
        }
      },
      onClickPreview(idx) {
        this.previewIndex = idx
        this.showPreview = true
      },
      getMomentDescription(item) {
        if (item.description) {
          return item.description
        } else {
          return ""
        }
      },
      getNickName(item) {
        if (!item || !item.user) {
          return ""
        }
        return item.user.nickname
      },
      imageUrls(item) {
        if (item && item.image_urls) {
          return item.image_urls
        } else {
          return []
        }
      },
      onClickDigg() {
        if (!this.isDigg) {
          this.isDigg = true
          this.addDigg = 1
        }

        this.$store.dispatch("DoItemUserAction", {"item_id": this.itemId, "action": "digg"}).then((data) => {
          if (data.code === 0) {}
        }, () => {

        })
      },
      createdAt(item) {
        return createdAtDesc(item.created_at)
      },
      isAuthor() {
        if (this.item && this.item.is_author) {
          return true
        } else {
          return false
        }
      },
      onClickDelete() {
        Dialog.confirm({
          message: '确认删除?'
        }).then(() => {
          let params = {
            id: this.itemId,
            status: this.itemStatusAuthorDelete,
          }
          this.$store.dispatch("UpdateItemStatus", params).then((data)=> {
            if (data.code === 0) {
              Toast("删除成功")
              this.$emit("onDeletePost")
            }
          }, ()=> {
            Toast("网络错误")
          })
        }).catch(() => {
          // on cancel
        });
      }
    },
    computed: {
      diggCountText() {
        if (this.item && this.item.digg_count) {
          return this.item.digg_count + this.addDigg
        } else if (this.addDigg) {
          return this.addDigg
        } else {
          return "赞"
        }
      },
    }
  };
</script>

<style lang="scss" scoped>

  .moment-list-container {
    background: white;
  }

  .moment-item-container {

    .title-container {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .user-info-container {
        display: flex;
        align-items: center;
        margin-left: 10px;

        .base-info-container {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          margin-left: 5px;

          .nickname {
            font-size: 14px;
            font-weight: bold;
            color: rgba(21, 21, 21, 0.87);
            line-height: 1.5;
          }

          .post-time {
            font-size: 12px;
            color: grey;
            line-height: 1.5;
          }

        }
      }

      .more-container {
        padding: 0 15px;
      }
    }

    .moment-info-box {
      font-size: 13px;

      .title-info {
        margin: 12px 15px;
        font-size: 16px;
      }

      .description-info {
        white-space: pre-wrap;
        -ms-word-break: break-all;
        word-break: break-all;
        margin: 12px 15px;
        line-height: 1.75;
        font-size: 14px;
        color: rgba(35, 35, 35, 0.94);
      }

      .cover-container {
        .cover-inner {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;

          li {
            width: 100%;
            margin-top: 2px;
          }
        }

        .img {
          max-width: 100%;
          height: auto;
        }
      }

      .flex {
        display: flex;
        flex-wrap: wrap;
      }

      .flex > li {
        /*flex-grow: 1; !* 子元素按1/n的比例进行拉伸 *!*/
        text-align: center;
        color: #fff;
      }

      .flex > li:nth-of-type(3n) { /* 选择个数是3的倍数的元素 */
        margin-right: 0;
      }

      .flex > li:nth-of-type(n+7) { /* 选择倒数的三个元素，n可以取0 */
        margin-bottom: 0;
      }

    }

    .item-action-box {
      display: flex;
      align-items: center;
      font-size: 14px;
      margin-top: 14px;
      justify-content: center;
      flex-direction: column;
      color: #444444;

      .action-item-container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 60px;
        height: 60px;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        /*border: 1px solid #e8e8e8;*/
        font-size: 42px;

        .icon-text {
          color: rgba(86, 86, 86, 0.95);
          margin-top: 3px;
          font-size: 12px;
        }
      }
    }
  }

  .moment-body {
    background: white;
  }

  .delete-icon {
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: rgba(84, 84, 84, 0.82);
    padding: 4px 15px;
  }

</style>
