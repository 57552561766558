<template>
  <div>
    <van-image-preview v-model="showPreview" :loop="false" :startPosition="startPosition" :lazyLoad="true" :images="urlList" @change="onChange"></van-image-preview>
  </div>
</template>

<script>
  export default {
    name: "ImagePreviewComponent",
    props: {
      value: {
      },
      urlList: {},
      startPosition: {}
    },
    mounted() {
    },
    data() {
      return {
        previewIndex: 0,
        showPreview: false
      }
    },
    methods: {
      onChange(idx) {
        this.previewIndex = idx
      },
    },
    watch: {
      showPreview(newValue) {
        this.$emit("input", newValue)
      },
      value() {
        this.showPreview = this.value
      }
    },
  }

</script>

<style lang="scss" scoped>
  .download-image-btn {
    position: fixed;
    bottom: 35px;
    right: 25px;
    z-index: 5000;
    color: white;
    font-size: 22px;

    .icon-class {
      color: white;
    }
  }
</style>
