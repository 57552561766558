<template>
  <div class="loader-container">
    <div class="loader">
    </div>
  </div>
</template>

<script>
  export default {
    name: "LoadingComponent"
  }
</script>


<style scoped>
  .loader-container {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }

  .loader {
    border: 4px solid #f3f3f3; /* Light grey */
    border-top: 4px solid rgba(46, 45, 42, 0.93); /* Blue */
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 1.2s linear infinite;
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
</style>
