<template>
  <div class="body" v-if="itemId">
    <div class="comment-input-logo-container" @click="onClickComment">
      <div class="input-logo">
        <div class="input-box"> 我有话要说...</div>
      </div>
    </div>

    <div v-if="commentList && commentList.length > 0" class="comment-list-container">
      <div class="container-comment-title">基友留言</div>
      <ul class="comment-list" v-for="comment in commentList" :key="comment.id">
        <li class="comment-item">
          <UserAvatar :userInfo="comment.user" :size="35"></UserAvatar>
          <div class="comment-info">
            <div class="comment-title-container">
              <div class="user-name">
                {{comment.user.nickname}}
              </div>
              <div class="right-box-container">
                <div class="rate-container">
                  <Rate v-if="comment.rate" v-model="comment.rate" :size="16" disabled readonly></Rate>
                </div>
                <div class="digg-action-container">
                <span class="action-item" @click="onClickDigg(comment)">
                    <icon-svg :class="comment.is_digg? 'digg-icon-font-fill': 'digg-icon-font'"
                              :icon-class="comment.is_digg ? 'zan':'zan1'"></icon-svg>
                </span>
                  <span class="digg-count">{{diggCount(comment)}}</span>
                </div>
              </div>
            </div>
            <div class="comment-text-container">
              <div :class="['comment-text', comment.status===1?'comment-del':'']">{{comment.content}}</div>
              <div class="comment-action-wrapper">
                <span class="comment-c-time">{{createdAt(comment)}}</span>
                <div class="actions">
                  <span class="action-item" @click="onClickReply(comment)">回复</span>
                  <span class="action-item" @click="onClickMore(comment)" v-if="isAuthor(comment)">
                    <icon-svg icon-class="more"></icon-svg>
                  </span>
                </div>
              </div>

              <div class="reply-content-container" v-if="replyList(comment).length > 0">
                <div class="reply-item" v-for="reply in replyList(comment)" :key="reply.id">
                  <UserAvatar :userInfo="reply.user" size="23"></UserAvatar>
                  <div class="reply-content-main">
                    <div class="reply-content">
                      <div class="reply-title-container">
                        <div>
                           <span class="reply-user-nickname">
                      {{reply.user.nickname}}
                    </span>
                          <span v-if="reply.reply_to_user">
                     <span>回复</span>
                    <span class="reply-user-nickname">
                      {{reply.reply_to_user.nickname}}
                      </span>
                    </span>
                        </div>
                        <div class="digg-action-container">
                          <span class="action-item" @click="onClickDigg(reply)">
                        <icon-svg :class="reply.is_digg? 'digg-icon-font-fill': 'digg-icon-font'"
                                  :icon-class="reply.is_digg ? 'zan':'zan1'"></icon-svg>
                      </span>
                          <span class="digg-count">{{diggCount(reply)}}</span>
                        </div>
                      </div>
                      <div :class="['reply-text', reply.status===1?'reply-del':'']">{{reply.content}}</div>
                    </div>
                    <div class="comment-action-wrapper">
                      <span class="comment-c-time">{{createdAt(reply)}}</span>
                      <div class="actions">
                        <span class="action-item" @click="onClickReply(reply)">回复</span>
                        <span class="action-item" @click="onClickMore(reply)" v-if="isAuthor(reply)">
                            <icon-svg icon-class="more"></icon-svg>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="empty-comment-div" v-else>暂无评论</div>
    <popup v-model="showCommentPopup" position="bottom">
      <div class="comment-input-container" :key="replyId">
        <div class="comment-input-title">
          <field v-model="commentText"
                 type="textarea"
                 class="input-comment"
                 rows="4"
                 :placeholder=placeholder
                 autofocus
          >
          </field>
          <Button class="btn-send" round :loading="postCommentLoading" @click="postComment">发送</Button>
        </div>
      </div>
    </popup>
    <ActionSheet v-model="showActions" :actions="actions" :round="false" cancel-text="取消" close-on-click-action
                 @select="onSelect" style="z-index:10000"></ActionSheet>
  </div>
</template>

<script>
  import {ActionSheet, Rate, Field, Dialog, Icon, Button, Toast, Popup} from "vant"
  import {createdAtDesc} from "../../util/common";
  import UserAvatar from "../common/UserAvatar";

  export default {
    name: "CommentInfo",
    components: {
      UserAvatar,
      Rate, Field, Button, Toast, Popup, Icon, ActionSheet
    },
    props: {
      itemId: {
        type: Number,
        default: 0,
      }
    },
    data() {
      return {
        showActions: false,
        actions: [{name: '删除'}],
        commentList: [],
        commentText: "",
        placeholder: "我有话要说...",
        replyId: 0,
        userInfo: {},
        postCommentLoading: false,
        showCommentPopup: false,
        showLoginPopup: false,
        showSetNickname: false,

        actionTargetComment: {}
      }
    },
    mounted() {
      this.init()
      this.getUserInfo()
    },
    methods: {
      init() {
        this.showCommentPopup = false
        this.commentText = ""
        this.postCommentLoading = false
        this.getItemComment()
      },

      onSelect(item) {
        // 默认情况下点击选项时不会自动收起
        // 可以通过 close-on-click-action 属性开启自动收起
        this.showActions = false;
        if (item.name === "删除") {
          this.onClickDelete(this.actionTargetComment)
        }
      },

      checkUserState() {
        if (!this.userInfo || !this.userInfo.id) {
          Toast("未登录")
          return false
        } else {
          return true
        }
      },

      getUserInfo() {
        this.$store.dispatch("AccountInfoMine").then((data) => {
          if (data.code === 0) {
            this.userInfo = data.data
          }
        })
      },

      postComment() {
        if (!this.commentText) {
          Toast("请输入内容")
          return
        }

        let params = {
          "item_id": this.itemId,
          "content": this.commentText,
        }
        if (this.replyId) {
          params.reply_id = this.replyId
        }
        this.postCommentLoading = true
        this.$store.dispatch("PostItemComment", params).then((data) => {
          this.init()
          this.postCommentLoading = false
        }, () => {
          Toast("网络异常")
          this.postCommentLoading = false
        })
      },

      getItemComment() {
        if (!this.itemId) {
          return
        }
        let params = {
          "item_id": this.itemId
        }
        this.$store.dispatch("GetItemComment", params).then((data) => {
          if (data.code === 0) {
            this.commentList = data.data.comments
          }
        })
      },

      isAuthor(comment) {
        if (comment.user_id === this.userInfo.id) {
          return true
        } else {
          return false
        }
      },

      diggCount(c) {
        if (c.digg_count) {
          return c.digg_count
        } else {
          return ''
        }
      },

      replyList(comment) {
        if (comment && comment.reply_comments) {
          return comment.reply_comments
        }
        return []
      },

      onClickComment() {
        if (!this.checkUserState()) {
          return
        }
        this.replyId = 0
        this.placeholder = "我有话要说..."
        this.showCommentPopup = true
      },

      onClickDelete(comment) {
        Dialog.confirm({
          message: '确认删除留言'
        }).then(() => {
          let params = {
            id: comment.id
          }
          this.$store.dispatch("DeleteComment", params).then((data) => {
            if (data.code === 0) {
              Toast("删除留言")
              comment.status = 1
              comment.content = "留言已删除"
            }
          })
        }).catch(() => {
        });
      },

      onClickMore(targetComment) {
        this.actionTargetComment = targetComment
        this.showActions = true
      },

      onClickReply(targetComment) {
        let isPass = this.checkUserState()
        if (!isPass) {
          return
        }
        if (targetComment && targetComment.id) {
          this.replyId = targetComment.id
        }
        if (targetComment.user.nickname) {
          this.placeholder = `回复 ${targetComment.user.nickname}：`
        }
        this.showCommentPopup = true
      },

      onClickDigg(comment) {
        let isPass = this.checkUserState()
        if (!isPass) {
          return
        }

        let id = comment.id
        // fake
        if (!comment.is_digg) {
          comment.is_digg = true
          if (comment.digg_count) {
            comment.digg_count += 1
          } else {
            comment.digg_count = 1
          }
        }

        this.$store.dispatch("DoItemUserAction", {"item_id": id, "action": "comment_digg"}).then((data) => {
          if (data.code === 0) {
          }
        }, () => {
        })
      },

      createdAt(comment) {
        return createdAtDesc(comment.created_at)
      },
    },

    computed: {}
  }
</script>

<style lang="scss" scoped>

  .section-title {
    font-size: 16px;
    color: #3b424c;
    font-weight: bold;
    padding: 4px 10px;
    background: white;
  }

  .comment-list-title {
    margin-top: 5px;
    padding: 8px 15px;
    font-size: 14px;
    color: rgba(128, 128, 128, 0.74);
    background: white;
  }

  .container-comment-title {
    padding: 16px 8px;
    color: rgba(0, 0, 0, 0.64);
  }

  .comment-input-logo-container {
    border-top: 1px solid #f2f2f2;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);

    .input-logo {
      flex: 1 1 auto;
      height: 100%;
      padding: 10px 15px;

      .input-box {
        -webkit-border-radius: 15px;
        -moz-border-radius: 15px;
        border-radius: 15px;
        background: rgba(225, 226, 227, 0.55);
        color: rgba(135, 143, 145, 0.89);
        font-size: 12px;
        padding: 0 15px;
        line-height: 2.5;
      }
    }

    .icon-like {
      padding: 0 18px 0 5px;
      font-size: 14px;
    }
  }

  .comment-list-container {
    padding-bottom: 45px;
  }

  .comment-list {
    background: white;
    padding: 0 8px;
  }

  .comment-item {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 8px 2px 6px 2px;
    border-bottom: 1px solid #e8e8e8;
  }

  .comment-action-wrapper {
    margin: 4px 0;
    display: flex;
    flex: 1;
    align-items: center;
    color: rgba(122, 123, 124, 0.9);
    font-size: 14px;

    .comment-c-time {
      font-size: 12px;
      flex: 1;
    }

    .actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .action-item {
        margin-left: 12px;
        margin-right: 8px;
        font-size: 12px;
        display: flex;
        align-items: center;
      }

      .digg-count {
        font-size: 12px;
        margin-left: 3px;
      }
    }

  }

  .comment-info {
    flex: 1;
    padding: 0 8px;


    .comment-title-container {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .right-box-container {
        display: flex;
      }

      .digg-action-container {
        color: rgba(122, 123, 124, 0.9);
        display: flex;
        align-items: center;
      }

      .action-item {
        margin-left: 12px;
      }

      .digg-count {
        min-width: 10px;
        font-size: 12px;
        margin-left: 3px;
      }
    }


    .user-name {
      font-size: 13px;
      color: rgba(122, 123, 124, 0.9);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .comment-text-container {
      word-wrap: break-word;

      .comment-text {
        white-space: pre-wrap;
        padding: 10px 0;
        font-size: 14px;
        color: #3b424c;
        line-height: 22px;
      }

      .comment-del {
        color: rgba(78, 87, 100, 0.63);
      }

      .reply-content-container {
        padding: 6px;
        background-color: #f4f5f5;

        .reply-item {
          border-radius: 1px;
          display: flex;
          margin-top: 8px;
        }

        .reply-content-main {
          display: flex;
          flex-direction: column;
          padding: 0 6px;
          flex: 1;
        }

        .reply-content {
          font-size: 12px;
          line-height: 20px;
          color: #3b424c;

          .reply-title-container {
            display: flex;
            align-items: center;
            justify-content: space-between;
          }

          .digg-action-container {
            color: rgba(122, 123, 124, 0.9);
            display: flex;
            align-items: center;
          }

          .action-item {
            margin-left: 12px;
          }

          .digg-count {
            min-width: 10px;
            margin-left: 3px;
          }

          .reply-user-nickname {
            color: rgba(122, 123, 124, 0.9);
          }

          .reply-text {
            white-space: pre-wrap;
            padding: 8px 0;
            font-size: 12px;
          }

          .reply-del {
            color: rgba(78, 87, 100, 0.63);
          }
        }
      }
    }
  }

  .comment-input-container {
    background: white;

    .comment-input-title {
      padding: 0 10px;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .btn-send {
        flex: 0 0 auto;
      }
    }

  }

  .digg-icon-font-fill {
    color: rgba(255, 0, 0, 0.69);
  }

  .digg-icon-font {
  }

  .empty-comment-div {
    padding: 20px 15px;
    font-size: 12px;
    color: rgba(149, 149, 149, 0.72);
    display: flex;
    align-items: center;
    justify-content: center;
  }

</style>
