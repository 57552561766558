<template>
  <div class="page-container-brand">
    <div v-for="item in brandItem.store_list" class="brand-item-container">
      <StoreItem :item="item"></StoreItem>
    </div>
  </div>
</template>
<script>

  import StoreItem from "./StoreItem";

  export default {
    name: "BrandPage",
    title: '门店列表',
    components: {StoreItem},
    data() {
      return {
        brandItem: {}
      }
    },
    mounted() {
      this.getBrand()
      this.initWxConfig()
    },
    methods: {
      initWxConfig() {
        let params = {
          "url": window.location.href.split('#')[0],
        }

        this.$store.dispatch("InitWxJsConfig", params).then((data) => {
          if (data.code === 0) {
            let conf = data.data
            wx.config({
              appId: conf.app_id, // 必填，公众号的唯一标识
              timestamp: conf.timestamp, // 必填，生成签名的时间戳
              nonceStr: conf.nonce, // 必填，生成签名的随机串
              signature: conf.signature,// 必填，签名
              jsApiList: [
                "openLocation"
              ] // 必填，需要使用的JS接口列表
            });
          }
        }, () => {
        })
      },
      getBrand() {
        let brandId = this.$route.query.id
        let params = {
          "id": brandId
        }
        this.$store.dispatch("GetBrand", params).then((data) => {
          if (data.code === 0) {
            this.brandItem = data.data
          }
        }, () => {

        })

      }

    }
  }
</script>

<style lang="scss">
  .brand-item-container {
    padding: 8px 12px;
  }

  .page-container-brand {
    background: #f2f2f2;
    min-height: 100vh;
  }

  .brand-title {
    font-size: 16px;
    font-weight: bold;
    padding: 8px 12px;
  }

  .store-title {
    padding: 8px 12px;
    color: #333;
  }


</style>
